import { Ref } from 'vue';

export function useClickOutside(ref: Ref, 
  handler: (e: PointerEvent) => void,
  additionalRef?: Ref,
  customEqualCheck?: (ref?: HTMLElement, target?: HTMLElement | null) => boolean,
) {
  if (process.server) {
    return;
  }

  if (!document || !ref) {
    return;
  }

  const listener = (e: PointerEvent) => {
    const composedPath = e.composedPath();
    if (
      customEqualCheck?.(ref.value, e.target as HTMLElement) ||
      !!composedPath.find((pathElement) => customEqualCheck?.(ref.value, pathElement as HTMLElement)) ||
      e.target === ref.value ||
      composedPath.includes(ref.value) ||
      e.target === additionalRef?.value ||
      composedPath.includes(additionalRef?.value) ||
      !!composedPath.find((pathElement) => customEqualCheck?.(additionalRef?.value, pathElement as HTMLElement)) ||
      customEqualCheck?.(additionalRef?.value, e.target as HTMLElement)
    ) {
      return;
    }

    handler(e);
  };

  onMounted(() => {
    document.body.addEventListener('click', listener);
    document.body.addEventListener('touchstart', listener);
  });

  onBeforeUnmount(() => {
    document.body.removeEventListener('click', listener);
    document.body.removeEventListener('touchstart', listener);
  });
}
